import { Box, Button, Container, Grid, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { selectDegreeNames, selectFieldNames, selectSchoolNames, selectSkillNames } from 'App/features/Education';
import {
  ADD_PROFILE_EDUCATION_ITEM,
  DELETE_PROFILE_EDUCATION_ITEM,
  EDIT_PROFILE_EDUCATION_ITEM,
  FETCH_DEGREE_NAMES,
  FETCH_FIELD_NAMES,
  FETCH_SCHOOL_NAMES,
  FETCH_SKILL_NAMES,
  initialEducation,
} from 'App/features/Education/constants';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { updateFormValue } from 'App/features/Education';
import { EducationItemInterface } from 'App/features/Education/types';
import { useAppDispatch, useAppSelector } from 'App/hooks';
import { ChipData } from 'components/ChipsList';
import { useTranslation } from 'react-i18next';
import useDebounce from 'utils/useDebounce';
import { v4 as uuidv4 } from 'uuid';
import { selectDirection } from '../../App/features/direction/directionSlice';
import CustomChip from '../../components/CustomChip';
import Ratings from '../Ratings';
import styles from './AddEducationForm.module.scss';
import { useStyles } from './styles';

import Vector1 from './vector/Vector1.svg';
import Vector2 from './vector/Vector2.svg';
import Vector3 from './vector/Vector3.svg';

interface AddEducationFormProps {
  onClose?: () => void;
  edit?: boolean;
  isOpen?: boolean;
  school: EducationItemInterface;
}

interface DataItem {
  name: string;
  value: number;
}

export function getRtlClass(direction: string, cssClass, cssClassRtl) {
  if (direction === 'rtl') {
    return cssClassRtl;
  } else {
    return cssClass;
  }
}

const AddEducationForm: React.FC<AddEducationFormProps> = ({
  edit = false,
  isOpen = true,
  onClose,
  school,
  onSave,
}): JSX.Element | null => {
  const [skillSearchValue, setSkillSearchValue] = useState('');
  const [degreeSearchValue, setDegreeSearchValue] = useState('');
  const [schoolSearchValue, setSchoolSearchValue] = useState('');
  const [fieldSearchValue, setFieldSearchValue] = useState('');
  const [skillsData, setSkillsData] = useState<ChipData[]>([]);
  const [skillExistsError, setSkillExistsError] = useState<boolean>(false);
  const { skills, degree, schoolName, fromYear, toYear, fieldOfStudy } = school;
  const [selectedDegreeName, setSelectedDegreeName] = useState<any>(degree?.name ? degree : null);
  const [selectedSchoolName, setSelectedSchoolName] = useState<any>(schoolName?.name ? schoolName : null);
  const [selectedFieldName, setSelectedFieldName] = useState<any>(fieldOfStudy?.name ? fieldOfStudy : null);
  const [selectedSkillName, setSelectedSkillName] = useState<any>(null);
  const skillNamesSource: any = useAppSelector(selectSkillNames);
  const degreeNamesSource: any = useAppSelector(selectDegreeNames);
  const schoolNamesSource: any = useAppSelector(selectSchoolNames);
  const fieldNamesSource: any = useAppSelector(selectFieldNames);
  const direction: string = useAppSelector(selectDirection);

  const debouncedSkillSearchValue = useDebounce(skillSearchValue, 500);
  const dispatch = useAppDispatch();

  const { t } = useTranslation('educationTranslate');

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 749);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  }, []);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth <= 749);
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      skills: skillsData,
      degree: degree?.name ? degree : null,
      schoolName: schoolName?.name ? schoolName : null,
      fromYear: fromYear || new Date().toISOString(),
      toYear: toYear || new Date().toISOString(),
      fieldOfStudy: fieldOfStudy?.name ? fieldOfStudy : null,
      CostAndFees: 0,
      EducationLevel: 0,
      InternshipOpportunities: 0,
      CampusLife: 0,
      CampusFacilities: 0,
    },
  });

  const yearNow = new Date().toISOString();
  const currentStartDateValue = watch('fromYear');
  const currentEndDateValue = watch('toYear');

  useEffect(() => {
    if (debouncedSkillSearchValue) {
      dispatch({ type: FETCH_SKILL_NAMES, payload: debouncedSkillSearchValue });
    }
  }, [debouncedSkillSearchValue, dispatch]);

  const debouncedDegreeSearchValue = useDebounce(degreeSearchValue, 500);

  useEffect(() => {
    if (debouncedDegreeSearchValue) {
      clearErrors('degree');
      dispatch({ type: FETCH_DEGREE_NAMES, payload: debouncedDegreeSearchValue });
    }
  }, [clearErrors, debouncedDegreeSearchValue, dispatch]);

  const debouncedSchoolSearchValue = useDebounce(schoolSearchValue, 500);

  useEffect(() => {
    if (debouncedSchoolSearchValue) {
      clearErrors('schoolName');
      dispatch({ type: FETCH_SCHOOL_NAMES, payload: debouncedSchoolSearchValue });
    }
  }, [clearErrors, debouncedSchoolSearchValue, dispatch]);

  const debouncedFieldSearchValue = useDebounce(fieldSearchValue, 500);

  useEffect(() => {
    if (debouncedFieldSearchValue) {
      clearErrors('fieldOfStudy');
      dispatch({ type: FETCH_FIELD_NAMES, payload: debouncedFieldSearchValue });
    }
  }, [clearErrors, debouncedFieldSearchValue, dispatch]);

  useEffect(() => {
    try {
      if (
        new Date(currentEndDateValue) < new Date(yearNow) &&
        new Date(currentEndDateValue) >= new Date(currentStartDateValue) &&
        !isNaN(Date.parse(currentEndDateValue))
      ) {
        clearErrors('toYear');
      }
      if (new Date(currentStartDateValue) < new Date(yearNow) && !isNaN(Date.parse(currentStartDateValue))) {
        clearErrors('fromYear');
      }
    } catch {
      console.log('invalid date');
    }
  }, [clearErrors, currentStartDateValue, currentEndDateValue]);

  const { fields, append, remove } = useFieldArray({
    name: 'skills',
    control,
  });

  const currentSkillsValue = watch('skills');
  const userSkillNames = currentSkillsValue.map(s => s.name);

  const classes = useStyles();

  useEffect(() => {
    if (skills?.length) {
      const newList = skills.map((skill, index) => ({
        id: index,
        name: skills[index].name,
        neoId: skills[index].neoId,
        checked: true,
      }));
      setSkillsData(newList);
      setValue('skills', newList);
    }
  }, [setValue, skills]);

  const handleSkillChange = (event, option) => {
    setSelectedSkillName(option);
  };

  const handleDegreeChange = (event, option) => {
    setSelectedDegreeName(option);
  };

  const handleSchoolChange = (event, option) => {
    setSelectedSchoolName(option);
  };

  const handleFieldChange = (event, option) => {
    setSelectedFieldName(option);
  };

  const handleAddSkill = () => {
    const length = skillsData.length;
    let newSkill;
    if (selectedSkillName) {
      newSkill = { id: length + 1, name: selectedSkillName.name, neoId: selectedSkillName.neoId, checked: true };
    } else {
      if (!skillSearchValue.length) return;
      if (userSkillNames.includes(skillSearchValue)) {
        setSkillExistsError(true);
        return;
      }
      newSkill = { id: length + 1, name: skillSearchValue, checked: true };
    }
    !edit && dispatch(updateFormValue({ key: 'skills', value: [...skillsData, newSkill] }));
    edit && append(newSkill);
    setSkillSearchValue('');
    setSelectedSkillName('');
    setSkillExistsError(false);
  };

  const handleDeleteSkill = (index: number) => {
    const newSkills = skillsData.filter((_, idx) => idx !== index);
    setSkillsData(newSkills);
    remove(index);
  };

  const validateForm = () => {
    const errs: string[] = [];
    if (!selectedDegreeName && !degreeSearchValue) {
      errs.push('degree');
      setError('degree', { type: 'required' });
    }
    if (!selectedSchoolName && !schoolSearchValue) {
      errs.push('schoolName');
      setError('schoolName', { type: 'required' });
    }
    if (!selectedFieldName && !fieldSearchValue) {
      errs.push('fieldOfStudy');
      setError('fieldOfStudy', { type: 'required' });
    }
    if (
      new Date(currentEndDateValue) > new Date(yearNow) ||
      new Date(currentEndDateValue) < new Date(currentStartDateValue)
    ) {
      errs.push('toYear');
      setError('toYear', { type: 'invalid' });
    }
    if (new Date(currentStartDateValue) > new Date(yearNow)) {
      errs.push('fromYear');
      setError('fromYear', { type: 'invalid' });
    }
    if (isNaN(Date.parse(currentStartDateValue))) {
      errs.push('fromYear');
      setError('fromYear', { type: 'invalid' });
    }
    if (isNaN(Date.parse(currentEndDateValue))) {
      errs.push('toYear');
      setError('toYear', { type: 'invalid' });
    }
    return errs;
  };

  const setInstitutionRating = data => {
    const storedRatings = localStorage.getItem('institutionRatings');
    if (storedRatings) {
      const ratings: DataItem[] = JSON.parse(storedRatings);
      ratings.forEach(item => {
        const formattedName = item.name.toLowerCase().replace(/\s+/g, '').replace(/[()-]/g, '');

        switch (formattedName) {
          case 'costandfeesperyear':
            data.CostAndFees = item.value;
            break;
          case 'educationlevel':
            data.EducationLevel = item.value;
            break;
          case 'internshipandcareeropportunities':
            data.InternshipOpportunities = item.value;
            break;
          case 'campuslife':
            data.CampusLife = item.value;
            break;
          case 'campusfacilities':
            data.CampusFacilities = item.value;
            break;
        }
      });
    }
  };
  const onSubmit = data => {
    const formErrors = validateForm();
    if (formErrors.length) {
      return;
    }
    setInstitutionRating(data);

    data.skills = data.skills.map(s => ({ name: s.name, neoId: s.neoId }));
    data._id = edit ? school._id : uuidv4();

    dispatch({
      type: edit ? EDIT_PROFILE_EDUCATION_ITEM : ADD_PROFILE_EDUCATION_ITEM,
      data: {
        ...data,
        degree: selectedDegreeName
          ? { name: selectedDegreeName.name, neoId: selectedDegreeName.neoId }
          : { name: degreeSearchValue },
        schoolName: selectedSchoolName
          ? { name: selectedSchoolName.name, recruiterId: selectedSchoolName.recruiterId }
          : { name: schoolSearchValue },
        fieldOfStudy: selectedFieldName
          ? { name: selectedFieldName.name, neoId: selectedFieldName.neoId }
          : { name: fieldSearchValue },
      },
    });
    setSkillsData([]);
    if (onClose) onClose();
  };

  const onDelete = (id: string) => {
    dispatch({ type: DELETE_PROFILE_EDUCATION_ITEM, id });
    if (onClose) onClose();
  };

  const RATINGS_DATA = [
    {
      name: 'ratings.rate.costAndFees',
      value: 0,
    },
    {
      name: 'ratings.rate.educationLevel',
      value: 0,
    },
    {
      name: 'ratings.rate.internshipAndCareerOpportunities',
      value: 0,
    },
    {
      name: 'ratings.rate.campusLife',
      value: 0,
    },
    {
      name: 'ratings.rate.campusFacilities',
      value: 0,
    },
  ];

  if (!isOpen) return null;

  return (
    <form className={styles.formRoot} onSubmit={handleSubmit(onSubmit)}>
      <Box className={styles.formLabel}>
        {edit ? t('educationForm.edit') : t('educationForm.add')} {t('educationForm.education')}
        <IconButton
          className={styles.closeButton}
          onClick={onClose}
          style={direction === 'rtl' ? { left: '1rem', right: 'unset' } : undefined}>
          <CloseIcon style={{ fontSize: 20 }} />
          <span>Close</span>
        </IconButton>
      </Box>
      <Container className={styles.formContainer}>
        {!isMobile ? (
          <>
            <img className={styles.vectorOne} src={Vector1} alt="Vector1" />
            <img className={styles.vectorTwo} src={Vector2} alt="Vector2" />
            <img className={styles.vectorThree} src={Vector3} alt="Vector3" />
          </>
        ) : null}

        <Grid container item sm={12} spacing={2} className={styles.inputSection}>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              options={degreeNamesSource}
              getOptionLabel={(option: any) => option.name}
              value={degree?.name ? degree : null}
              onChange={handleDegreeChange}
              className={
                degreeSearchValue.length > 0
                  ? getRtlClass(direction, classes.autocompleteNoIcon, classes.autocompleteNoIconRtl)
                  : getRtlClass(direction, classes.autocomplete, classes.autocompleteRtl)
              }
              openOnFocus={false}
              clearOnBlur={false}
              renderInput={params => (
                <>
                  <Box className={styles.fieldLabel}>{t('Degree')}</Box>
                  <TextField
                    {...params}
                    // placeholder={t('educationForm.placeholders.searchDegrees')}
                    className={
                      errors.degree
                        ? `${classes.inputError} ${getRtlClass(direction, classes.input, classes.inputRtl)}`
                        : getRtlClass(direction, classes.input, classes.inputRtl)
                    }
                    value={degreeSearchValue}
                    type="text"
                    id="degree"
                    {...register('degree', {
                      required: true,
                    })}
                    onChange={e => setDegreeSearchValue(e.currentTarget.value)}
                  />
                </>
              )}
            />
            {errors.degree && <span className={styles.error}>{t('educationForm.errors.degreeRequired')}</span>}
          </Grid>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              options={schoolNamesSource}
              getOptionLabel={(option: any) => option.name}
              value={schoolName?.name ? schoolName : null}
              onChange={handleSchoolChange}
              className={
                schoolSearchValue.length > 0
                  ? getRtlClass(direction, classes.autocompleteNoIcon, classes.autocompleteNoIconRtl)
                  : getRtlClass(direction, classes.autocomplete, classes.autocompleteRtl)
              }
              openOnFocus={false}
              clearOnBlur={false}
              renderInput={params => (
                <>
                  <Box className={styles.fieldLabel}>{t('School')}</Box>
                  <TextField
                    {...params}
                    // placeholder={t('educationForm.placeholders.schoolNames')}
                    className={
                      errors.schoolName
                        ? `${classes.inputError} ${getRtlClass(direction, classes.input, classes.inputRtl)}`
                        : getRtlClass(direction, classes.input, classes.inputRtl)
                    }
                    value={schoolSearchValue}
                    type="text"
                    id="schoolName"
                    {...register('schoolName', {
                      required: true,
                    })}
                    onChange={e => setSchoolSearchValue(e.currentTarget.value)}
                  />
                </>
              )}
            />
            {errors.schoolName && <span className={styles.error}>{t('educationForm.errors.schoolNameRequired')}</span>}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={fieldNamesSource}
              getOptionLabel={(option: any) => option.name}
              value={fieldOfStudy?.name ? fieldOfStudy : null}
              onChange={handleFieldChange}
              style={{ width: !isMobile ? '80%' : '100%' }}
              className={
                fieldSearchValue.length > 0
                  ? getRtlClass(direction, classes.autocompleteNoIcon, classes.autocompleteNoIconRtl)
                  : getRtlClass(direction, classes.autocomplete, classes.autocompleteRtl)
              }
              openOnFocus={false}
              clearOnBlur={false}
              renderInput={params => (
                <>
                  <Box className={styles.fieldLabel}>{t('educationForm.studyField')}</Box>
                  <TextField
                    {...params}
                    style={{ width: !isMobile ? '50%' : '100%' }}
                    // placeholder={t('educationForm.placeholders.searchFields')}
                    className={
                      errors.fieldOfStudy
                        ? `${classes.inputError} ${getRtlClass(direction, classes.input, classes.inputRtl)}`
                        : getRtlClass(direction, classes.input, classes.inputRtl)
                    }
                    value={fieldSearchValue}
                    type="text"
                    id="fieldOfStudy"
                    {...register('fieldOfStudy', {
                      required: true,
                    })}
                    onChange={e => setFieldSearchValue(e.currentTarget.value)}
                  />
                </>
              )}
            />
            {errors.fieldOfStudy && (
              <span className={styles.error}>{t('educationForm.errors.studyFiledRequired')}</span>
            )}
          </Grid>
          {/* <--- Left Year ---> */}
          <Grid item xs={12} sm={6}>
            <Box className={styles.fieldLabel}>{t('Start date')}</Box>
            <Controller
              name="fromYear"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, name, value, ref } }) => {
                return (
                  <KeyboardDatePicker
                    disableToolbar
                    name={name}
                    inputRef={ref}
                    okLabel={t('educationForm.ok').toUpperCase()}
                    cancelLabel={t('educationForm.cancel')}
                    className={classes.input}
                    placeholder="Month with Years"
                    views={['year', 'month']}
                    maxDate={new Date().toISOString()}
                    disableFuture
                    onChange={date => {
                      dispatch(
                        updateFormValue({
                          key: name,
                          value: date && date.toDateString() !== 'Invalid Date' ? date.toISOString() : '',
                        }),
                      );
                      onChange(date);
                    }}
                    value={value}
                    keyboardIcon={<CalendarTodayIcon color="secondary" style={{ fontSize: 18 }} />}
                  />
                );
              }}
            />
          </Grid>
          {/* <--- Right Year ---> */}
          <Grid item xs={12} sm={6}>
            <Box className={styles.fieldLabel}>{t('End date (or expected)')}</Box>
            <Controller
              name="toYear"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <KeyboardDatePicker
                  disableToolbar
                  okLabel={t('educationForm.ok').toUpperCase()}
                  cancelLabel={t('educationForm.cancel')}
                  inputRef={ref}
                  className={classes.input}
                  placeholder="Month with Years"
                  views={['year', 'month']}
                  openTo="year"
                  disableFuture
                  minDate={currentStartDateValue}
                  maxDate={new Date().toISOString()}
                  onChange={date => {
                    dispatch(
                      updateFormValue({
                        key: name,
                        value: date && date.toDateString() !== 'Invalid Date' ? date.toISOString() : '',
                      }),
                    );
                    onChange(date);
                  }}
                  value={value}
                  keyboardIcon={<CalendarTodayIcon color="secondary" style={{ fontSize: 18 }} />}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} className={styles.searchBlock}>
            <Autocomplete
              options={skillNamesSource.filter(s => !userSkillNames.includes(s.name))}
              className={
                skillSearchValue.length > 0
                  ? getRtlClass(direction, classes.autocompleteNoIcon, classes.autocompleteNoIconRtl)
                  : getRtlClass(direction, classes.autocomplete, classes.autocompleteRtl)
              }
              openOnFocus={false}
              onChange={(event, option) => {
                if (option) {
                  const newSkill = { id: uuidv4(), name: option.name, neoId: option.neoId, checked: true };
                  if (!userSkillNames.includes(option.name)) {
                    setSkillsData([...skillsData, newSkill]);
                    append(newSkill);
                  }
                  setSkillSearchValue('');
                  setSelectedSkillName(null);
                  setSkillExistsError(false);
                }
              }}
              getOptionLabel={option => option.name}
              clearOnBlur={false}
              inputValue={skillSearchValue}
              onInputChange={(event, newInputValue) => {
                setSkillSearchValue(newInputValue);
              }}
              value={selectedSkillName}
              renderInput={params => (
                <>
                  <Box style={{ fontSize: '20px' }} className={styles.fieldLabel}>
                    {t('educationForm.skills')}
                  </Box>
                  <TextField
                    {...params}
                    placeholder={t('Start typing and select skills')}
                    className={getRtlClass(direction, classes.input, classes.inputRtl)}
                    type="text"
                    id="Skill"
                    name="skill"
                  />
                </>
              )}
            />
          </Grid>
          {skillExistsError && <span className={styles.error}>{t('educationForm.errors.skillAdded')}</span>}
          <Box className={styles.buttonsBlock}>
            {fields.map((field, index) => (
              <CustomChip
                key={`${field.id}`}
                {...register(`skills.${index}` as const)}
                item={field}
                withCloseBtn
                onClickClose={() => handleDeleteSkill(index)}
              />
            ))}
          </Box>
          <Ratings currentEducationId={school._id} ratingsData={RATINGS_DATA} translateData="educationTranslate" />
          {/* <Box className={styles.submitBlock}>
            <Button variant="contained" className={styles.submitButton} type="submit">
              {t('Save changes')}
            </Button>
            {!edit && (
              <Button variant="contained" className={styles.cancelButton} onClick={onClose}>
                {t('Delete section')}
              </Button>
            )}
            {edit && (
              <Button
                variant="contained"
                color="secondary"
                className={styles.deleteButton}
                onClick={() => onDelete(school._id)}>
                {t('educationForm.delete')}
              </Button>
            )}
          </Box> */}
        </Grid>
      </Container>
    </form>
  );
};

export default AddEducationForm;
