import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Ratings.module.scss';
import { initialEducation } from '../../App/features/Education/constants';

interface RatingItem {
  name: string;
  value: number;
}

interface RatingsProps {
  ratingsData: RatingItem[];
  translateData: string;
  currentEducationId: string | null;
}

const Ratings: React.FC<RatingsProps> = ({ ratingsData, translateData, currentEducationId }) => {
  const { t } = useTranslation(translateData);
  const [ratings, setRatings] = useState<RatingItem[]>(ratingsData);

  useEffect(() => {
    const storedRatings = localStorage.getItem(`Ratings_${currentEducationId}`);
    if (storedRatings) {
      setRatings(JSON.parse(storedRatings));
    } else {
      // Если данных в LocalStorage нет, сбрасываем рейтинги к исходным
      setRatings(ratingsData);
    }
  }, [currentEducationId, ratingsData]);

  useEffect(() => {
    localStorage.setItem(`Ratings_${currentEducationId}`, JSON.stringify(ratings));
  }, [ratings, currentEducationId]);

  // useEffect(() => {
  //   setRatings(ratingsData);
  // }, [ratingsData]);

  const GRADE_RATING_DATA = [
    'ratings.grade.awful',
    'ratings.grade.bad',
    'ratings.grade.ok',
    'ratings.grade.good',
    'ratings.grade.excellent',
  ];

  const handleRatingClick = (itemIndex: number, value: number) => {
    const updatedRatings = ratings.map((item, index) => {
      if (index === itemIndex) {
        if (value === 1 && item.value === 1) {
          return { ...item, value: 0 };
        }
        return { ...item, value };
      }
      return item;
    });
    setRatings(updatedRatings);
  };

  return (
    <div className={styles.ratings}>
      <div className={styles.infoHeader}>
        <span className={styles.ratingsHeaderTitle}>
          <span>{t('ratings.title')}</span>
        </span>
      </div>
      <div className={styles.grades}>
        {GRADE_RATING_DATA.map((grade, index) => (
          <span key={index} className={styles.grade}>
            {t(`${grade}`)}
          </span>
        ))}
      </div>
      <div className={styles.ratingItems}>
        {ratings.map((item, itemIndex) => (
          <div key={itemIndex} className={styles.ratingItem}>
            <span>{t(`${item.name}`)}</span>
            <div className={styles.ratingDivIcon}>
              {[...Array(5)].map((_, i) => (
                <span
                  key={i}
                  className={styles.ratingIcon}
                  style={{
                    background:
                      i < item.value
                        ? `linear-gradient(
                        270deg,
                        #01ada9 0%,
                        rgba(1, 173, 169, 0.81) 42.52%,
                        rgba(1, 173, 169, 0.61) 105.41%,
                        rgba(1, 173, 169, 0) 117.65%
                      )`
                        : 'none',
                    borderRight: '2px solid #fff',
                  }}
                  onClick={() => handleRatingClick(itemIndex, i + 1)}></span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ratings;
